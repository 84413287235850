import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";




export default function TermsCondition({ dynamicCss }) {
    const [expanded, setExpanded] = React.useState(""); // Set "panel3" as the default expanded panel

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Box sx={{
                padding: "0 16px"
            }}>
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid #C1C1C133",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                        color: "#000",
                        ...dynamicCss
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel1" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '600',
                            lineHeight: "18px",

                        }}
                    >
                        What is the Explorers Club?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '400',
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        The Explorers Club is a loyalty program by CashKaro for Booking.com. It rewards you with Amazon vouchers and access to increased Cashback rates on travel-related brands as you make more hotel and homestay bookings through CashKaro.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel2" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        What are the benefits of joining the Explorers Club?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li>Flat 4% Cashback on all hotel and homestay bookings via Booking.com through CashKaro.</li>
                            <li>Minimum 15% Off on hotel and homestay bookings.</li>
                            <li>Exclusive Loyalty Rewards in the form of Amazon vouchers after reaching booking milestones.</li>
                            <li>Access to Increased Cashback Rates on various travel-related brands after your next booking.</li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1.5px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel3" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        How does the rewards program work?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: '400',
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        <ul>
                            <li><b>Baseline Booking History:</b> We’ll assess your booking history over the past year.</li>
                            <li><b>Unlocking Rewards:</b> For each booking you make beyond your previous year’s total (from the program launch until December 31st, 2025), you unlock Amazon vouchers.</li>
                            <li><b>Progress Tracking:</b> Track your progress on the Explorers Club Members Page. </li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel4" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Do previous bookings count towards unlocking rewards?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        No, only bookings made after you join the Explorers Club and after the program launch date count towards unlocking rewards.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel5" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="5-content"
                        id="5-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        How do I access the increased Cashback rates on other travel brands?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        After your next Booking.com booking is successfully tracked by CashKaro, the increased Cashback rates on other travel-related brands will be unlocked on your Explorers Club Members Page. You can then click the “Buy Now” buttons to shop and earn extra Cashback.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel6" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="6-content"
                        id="6-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        When will my booking be tracked and reflected in my account?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        Bookings are typically tracked within 72 hours of your transaction. You’ll receive a notification once your booking is tracked, and it will appear in your account’s booking history.


                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel7" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="7-content"
                        id="7-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        How will I receive my Amazon voucher rewards?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        Once you unlock an Amazon voucher by completing the required number of bookings and after all the associated trips have been completed, we will send the voucher code to your registered email address within 7-14 days. Please note that cancelled bookings will not count towards unlocking rewards or receiving vouchers.



                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel8"}
                    onChange={handleChange("panel8")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel8" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="8-content"
                        id="8-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Can I use the increased Cashback rates on travel brands before making a Booking.com booking?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        No, the increased Cashback rates on other travel brands are unlocked only after your next Booking.com booking is successfully tracked through CashKaro.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel9"}
                    onChange={handleChange("panel9")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel9" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="9-content"
                        id="9-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Do cancellations or refunds affect my rewards?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        Yes, if a booking is canceled or refunded, it will not count towards unlocking rewards, and any Cashback from that booking will be voided.

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel10"}
                    onChange={handleChange("panel10")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel10" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="10-content"
                        id="10-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Is there a deadline to complete the bookings to unlock rewards?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        Yes, all bookings must be made before December 31st, 2025 of the current year to qualify for rewards under this program.


                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel11"}
                    onChange={handleChange("panel11")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel11" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="11-content"
                        id="11-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        How will I receive Amazon voucher rewards?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
Once you unlock an Amazon voucher by completing the required number of bookings and after all the associated Cashbacks have been Confirmed, we will send the voucher code to your registered email address within 7-14 days. Please note that cancelled bookings will not count towards unlocking rewards or receiving vouchers.

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel12"}
                    onChange={handleChange("panel12")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel12" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="12-content"
                        id="12-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        I made a booking but it hasn’t been tracked yet. What should I do?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        If your booking isn’t tracked within 72 hours, please contact our customer support with your booking details, and we’ll assist you in resolving the issue.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel13"}
                    onChange={handleChange("panel13")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel13" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="13-content"
                        id="13-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Are flights or other travel services included in this program?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        The rewards are based on hotel and homestay bookings made through Booking.com via CashKaro.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel14"}
                    onChange={handleChange("panel14")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel14" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="14-content"
                        id="14-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        Can I participate in the Explorers Club if I’m already a Booking.com Genius member?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        Yes, you can. The Explorers Club benefits are in addition to your Booking.com Genius perks. Just make sure to start your booking from CashKaro.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel15"}
                    onChange={handleChange("panel15")}
                    sx={{
                        marginBottom: "16px",
                        borderRadius: "14px !important",
                        border: "1px solid rgba(193, 193, 193, 0.20)",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(7px)",
                        boxShadow: "0px 0px 0px 0px !important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={expanded === "panel15" ? <RemoveIcon sx={{ color: '#000', fontSize: '16px !important' }} /> : <AddIcon sx={{ color: '#000', fontSize: '16px !important' }} />}
                        aria-controls="15-content"
                        id="15-header"
                        sx={{
                            color: "#000",
                            fontFamily: "Metropolis ",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                        }}
                    >
                        What happens after I unlock all three Amazon vouchers?
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            color: "#000",
                            WebkitTextStrokeWidth: "1",
                            WebkitTextStrokeColor: "rgba(193, 193, 193, 0.20)",
                            fontFamily: "Metropolis",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "0.28px",
                            paddingTop: '0'
                        }}
                    >
                        After unlocking all three vouchers, you continue to earn the standard 4% Cashback on bookings. While no additional vouchers are provided under this program, stay tuned for future promotions!
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
}

