export const retailersGroup1 = [
  {
    name: "Mcaffeine",
    strikethrough_cashback: "18%",
    new_cashback: "20%",
    greater_savings: "11%",
    image: "mcaffeine",
    url: "cashkaro://stores/mcaffeine-coupons-fpp24"
  },
  {
    name: "Hyugalife",
    strikethrough_cashback: "8%",
    new_cashback: "10%",
    greater_savings: "25%",
    image: "Hyu",
    url: "cashkaro://stores/hyugalife-coupons-fpp24"
  },
  {
    name: "Zandu Care",
    strikethrough_cashback: "16%",
    new_cashback: "22%",
    greater_savings: "27%",
    image: "zandu-care",
    url: "cashkaro://stores/zanducare-coupons-fpp24"
  },
];

export const retailersGroup2 = [
  {
    name: "Boat",
    strikethrough_cashback: "5%",
    new_cashback: "7%",
    greater_savings: "40%",
    image: "boat",
    url: "cashkaro://stores/boat-coupon-fpp24"
  },
  {
    name: "MamaEarth",
    strikethrough_cashback: "18%",
    new_cashback: "Upto 22%",
    greater_savings: "22%",
    image: "mamaearth",
    url: "cashkaro://stores/mama-earth-coupons-hide-fpp24"
  },
  {
    name: "Puma",
    strikethrough_cashback: "4.50%",
    new_cashback: "6%",
    greater_savings: "33%",
    image: "puma",
    url: "cashkaro://stores/puma-coupons-fpp24"
  }
];

export const retailersGroup3 = [
  {
    name: "Fitspire",
    strikethrough_cashback: "20%",
    new_cashback: "27%",
    greater_savings: "35%",
    image: "fitspire",
    url: "cashkaro://stores/fitspire-coupons-fpp24"
  },
  {
    name: "Booking.com",
    strikethrough_cashback: "4%",
    new_cashback: "5%",
    greater_savings: "25%",
    image: "booking-com",
    url: "cashkaro://stores/booking-fpp24"
  },
  {
    name: "XYXX Crew",
    strikethrough_cashback: "25%",
    new_cashback: "30%",
    greater_savings: "20%",
    image: "xyxx-crew",
    url: "cashkaro://stores/xyxx-discount-code-fpp24"
  }
];

export const retailersGroup4 = [
  {
    name: "Bella Vita",
    strikethrough_cashback: "18%",
    new_cashback: "22%",
    greater_savings: "22%",
    image: "bella-vita",
    url: "cashkaro://stores/bella-vita-coupons-fpp24"
  },
  {
    name: "SSBeauty",
    strikethrough_cashback: "25%",
    new_cashback: "30%",
    greater_savings: "20%",
    image: "ssbeauty",
    url: "cashkaro://stores/ssbeauty-coupons-fpp24"
  },
  {
    name: "Max Protein",
    strikethrough_cashback: "30%",
    new_cashback: "40%",
    greater_savings: "33%",
    image: "max-protein",
    url: "cashkaro://stores/max-protein-coupons-fpp24"
  }
];

export const retailersGroup5 = [
  {
    name: "Hyphen",
    strikethrough_cashback: "12%",
    new_cashback: "14%",
    greater_savings: "30%",
    image: "Hyphen",
    url: "cashkaro://stores/hyphen-coupon-codes-fpp24"
  },
  {
    name: "Bombay Shaving Company",
    strikethrough_cashback: "15%",
    new_cashback: "18%",
    greater_savings: "20%",
    image: "bombay-shaving-company",
    url: "cashkaro://stores/bombay-shaving-company-coupons-fpp24"
  },
  {
    name: "Renee Cosmetics",
    strikethrough_cashback: "18%",
    new_cashback: "22%",
    greater_savings: "22%",
    image: "Renee",
    url: "cashkaro://stores/renee-cosmetics-coupons-fpp24"
  }
];

export const retailersGroup6 = [
  {
    name: "Adidas",
    strikethrough_cashback: "10%",
    new_cashback: "12%",
    greater_savings: "20%",
    image: "adidas",
    url: "cashkaro://stores/adidas-fpp24"
  },
  {
    name: "Times Prime",
    strikethrough_cashback: "₹250",
    new_cashback: "₹310",
    greater_savings: "24%",
    image: "times-prime",
    url: "cashkaro://stores/times-prime-coupons-fpp24"
  },
  {
    name: "Dot and Key",
    strikethrough_cashback: "15%",
    new_cashback: "18%",
    greater_savings: "20%",
    image: "dot-and-key",
    url: "cashkaro://stores/dotandkey-coupons-fpp24"
  }
];

export const retailersGroup7 = [
  {
    name: "The Man Company",
    strikethrough_cashback: "15%",
    new_cashback: "18%",
    greater_savings: "20%",
    image: "the-man-company",
    url: "cashkaro://stores/themancompany-coupons-fpp24"
  },
  {
    name: "Smytten",
    strikethrough_cashback: "6%",
    new_cashback: "9%",
    greater_savings: "50%",
    image: "smytten",
    url: "cashkaro://stores/smytten-coupon-codes-fpp24"
  },

];

export const retailersGroup8 = [



];

export const retailersGroup9 = [
 

];
