import React, { useState } from "react";
import { Grid, Typography, Box, Slider, Button } from "@mui/material";
import { styled } from "@mui/material";
import maintainshopping from "../../images/savings-league/shopping-streak.png";
import thumbimg from "../../images/diwali/thumbimg.png";
// import maintainshoppingimg from "../../images/diwali/maintainshoppingimg.png";
import point200 from "../../images/savings-league/amazon (2).png";
import point200active from "../../images/savings-league/amazon-active.png";
import point300 from "../../images/savings-league/amazon (3).png";
import point300active from "../../images/savings-league/amazon2-active.png";
import point500 from "../../images/savings-league/amazon (4).png";
import point500active from "../../images/savings-league/amazon3-active.png";

// import point500active from "../../images/diwali/point500active.png";
import win200 from "../../images/savings-league/winnig.png";
import win300 from "../../images/diwali/win300.png";
import win500 from "../../images/diwali/win500.png";
import ButtonComponent from "../../components/saving-league/buttonBottom";


const CustomSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-mark.MuiSlider-markActive:first-child": {
    background: "transparent",
  },
}));

const images = {
  2: point200active,
  3: point300active,
  5: point500active,
};

const marks = [
  { value: 0,  },
  { value: 2,},
  { value: 3,  },
  { value: 5, },
];
export default function MaintainShopping() {
  const [sliderValue, setSliderValue] = useState(localStorage.getItem("savings_current_streak") || 0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{  background: 'linear-gradient(180deg, #010A26 23.35%, #05248C 100%)', }}>
        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Grid pb={2} px={3}>
            <img src={maintainshopping} style={{ width: "100%" }} />
          </Grid>

          <Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0 0 16px",
                gap:"0",
                maxWidth:"350px",
                margin:"0 auto"
              }}
            >
              <Box
              sx={{
                position:"relative",
                left:"30px",
                width:"33.3%",
                minWidth:"138px",
              }}
              >
                <img
                  src={sliderValue >= 2 ? images[2] : point200}
                  style={{ width: "100%" }}
                />

                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 2
                </Typography>
              </Box>
              <Box
              sx={{
                position:"relative",
                width:"33.3%",
                minWidth:"138px",
              }}
             
              >
                <img
                  src={sliderValue >= 3 ? images[3] : point300}
                  style={{ width: "100%" }}
                />
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 3
                </Typography>
              </Box>
              <Box
              sx={{
                position:"relative",
                right:"30px",
                width:"33.3%",
                minWidth:"138px",
              }}
              >
                <img
                  src={sliderValue >= 5 ? images[5] : point500}
                  style={{ width: "100%" }}
                />
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Metropolis",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "10px",
                    position: "relative",
                    top: "-10px",
                  }}
                >
                  Day 5
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "0 40px",
                position: "relative",
              }}
            >
       <CustomSlider
    aria-label="Temperature"
    defaultValue={0}
    value={sliderValue}
    onChange={handleSliderChange}
    valueLabelDisplay="on"
    valueLabelFormat={(value) => (value === 0 ? "No streak yet" : `You are at day ${value}`)}
    step={1}
    marks={marks}
    disabled
    min={0}
    max={5}
    sx={{
        color: "#E85F34", // Change the color here
        padding: "4px !important",
        boxShadow: "0px 0px 0px",
        borderRadius: "20px",
        zIndex: "222",
        fontFamily:'Metropolis',
        position: "relative",
        height: 6,
        '& .MuiSlider-valueLabel': {
            bgcolor: 'transparent', // Background color of the label
            color: 'white', // Text color of the label
            borderRadius: 1, // Rounded corners
            padding: '4px 8px', // Padding around the label
            fontSize: "8px",
            position: "absolute",
            top: "calc(100% + 16px)", // Adjust to place tooltip below
            left: "-65%",
            transform: "translateX(-50%)", // Center tooltip horizontally
        },
        '& .MuiTooltip-tooltip': {
          
          bgcolor: 'transparent', // Background color of the label
          color: 'white', // Text color of the label
          borderRadius: 1, // Rounded corners
          padding: '4px 8px', // Padding around the label
          fontSize: "8px",
          position: "absolute",
           top: "calc(100% + 16px)",
          left: "50%",
          transform: "translateX(-50%)", // Center tooltip horizontally
        },
        '&.Mui-disabled': {
            color: '#E85F34', // Custom color for disabled state
        },
        "& .MuiSlider-thumb": {
            height: 26,
            width: 37,
            background: `url(${thumbimg})`, // Change thumb image
            "&:focus, &:hover, &.Mui-active": {
                boxShadow: "inherit",
            },
            ":before": {
                boxShadow: "0px 0px 0px",
            },
        },
        "& .MuiSlider-track": {
            height: 6, // Change track height
        },
        "& .MuiSlider-rail": {
            height: 6, // Change rail height
            backgroundColor: "#36276A", // Rail color
            opacity: "1",
        },
        "& .MuiSlider-mark": {
            backgroundColor: "#36276A", // Mark color
            height: 12, // Mark height
            width: 2, // Mark width
            borderRadius: "20px",
            '&.MuiSlider-markActive': {
                backgroundColor: 'transparent', // Active mark color
            },
        },
        "& .MuiSlider-markLabel": {
            color: "#fff",
            fontSize: "8px",
            top: "24px !important",
        },
        "& .MuiSlider-markLabel:first-of-type": {
            marginLeft: "8px",
        },
        "& .MuiSlider-markLabel:last-of-type": {
            marginRight: "8px",
        },
    }}
/>

            </Box>

            <Box>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.50)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  paddingBottom: "40px",
                  paddingTop: "32px",
                }}
              >
                *Last updated on {localStorage.getItem('formated_date')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: "129px", background:'transparent'}} sx={12}>
          <img src={win200} style={{ width: "100%" }} />

      
     

        </Grid>
        <a href='cashkaro://home'><ButtonComponent title="Start Your Streak"/></a>
        
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>{" "}
    </Grid>
  );
}
