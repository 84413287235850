import { Box, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ButtonComponent = ({ title, url }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url);
    };

    return (
        <Grid container sx={{ position: 'relative', zIndex: '99' }}>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                background: ' #05248C',
                paddingY: '16px',
            }}>
                <Box sx={{ marginX: '1rem' }}>
                    <Button
                        variant="contained"
                        onClick={handleClick}
                        sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '12px',
                            color: '#0F0A1A',
                            padding: '16px',
                            fontWeight: '700',
                            fontSize: '14px',
                            fontFamily: 'Metropolis',
                            textTransform: 'none',
                            boxShadow: '0px 0px 13px #FFFFFF',
                            boxShadow: '0px 10px 8px -10px #FFFFFF, 0px -10px 7px -10px #FFFFFF',
                            '&:hover': {
                                background: 'rgba(255, 255, 255, 0.80)',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        {title}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ButtonComponent;
