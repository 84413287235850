import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, Box, FormHelperText, SwipeableDrawer, Divider, Avatar, Alert, AlertTitle, Stack } from '@mui/material';
// import Topbanner from '../../images/main-banner.png';
import background from '../../images/diwali/leaderboard-hero-bg.png';
import BikeImage from '../../images/diwali/new/Prizes.gif';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Heading from '../../images/diwali/register-heading.png';
import Leaderbd from '../../images/diwali/lb.png';
import Assured from '../../images/diwali/assured.png';
import AboutPrize from '../../images/diwali/about-prize.png';
import Footer from '../../images/diwali/footer.png';
import PastWinner from '../../components/diwali/pastWinner';
import PastWinnerBg from '../../images/diwali/pastwinner-bg.png';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import Terms from '../../components/saving-league/t&cIndex';
import ButtonComponent from '../../components/saving-league/buttonBottom';
import UserDetail from '../../components/diwali/userDetail';
import TermImg from '../../images/diwali/term-white.png';
import BorderIMG from '../../images/diwali/border.png';
import Button from '@mui/material/Button';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AvatarImage from "../../images/diwali/avator12.png";
import AvatarImage1 from "../../images/diwali/icon/Avator1.png";
import AvatarImage2 from "../../images/diwali/icon/Avator2.png";
import AvatarImage3 from "../../images/diwali/icon/Avator3.png";
// import BG from '../../images/diwali/leaderbgs.png';
import BG from '../../images/savings-league/banner.png';
import borderline from '../../images/diwali/Line 124.png';
import EmptyPopUp from "../../components/saving-league/emptyPopup";
import DataPopUp from "../../components/saving-league/DataPopup";
import AvatorBg from '../../images/diwali/avator-bg.png';
import { getRandomName, namesArray } from './namesArray';



const leaderboardData = [
    { name: 'YOU', rank: '1000', points: '99,999', avatar: AvatarImage1, highlight: true },
    { name: 'Komal Sunil...', rank: '01', points: '01', avatar: AvatarImage2 },
    { name: 'Komal Sunil...', rank: '01', points: '01', avatar: AvatarImage3 },
    // Add more data as needed...
];
export default function Signup() {

    const navigate = useNavigate();
    const [checked, setChecked] = useState(true); // Set initial state to true for pre-checking

    const [leaderboard, setLeaderboard] = useState([]);
    const [weeklyLeaderboard, setWeeklyLeaderboard] = useState([])
    const [userData, setUserData] = useState(null);
    const [openTermsCondition, setOpenTermsCondition] = useState(false);
    const [openDataPopup, setOpenDataPopup] = useState(false);
    const scrollToElementt = () => { formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); };
    const formReff = useRef(null);

    const params = new URLSearchParams(window.location.search);
    const ck_id = params.get('ck_id') || localStorage.getItem('savings_league_ck_id');


    const fetchLeaderboard = async () => {
        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/getleaderboard_cdl_jan?ck_id=${ck_id}`);
            const data = await response.json();

            data.rows.unshift({
                name: "YOU",
                rank: data?.user_data?.user?.leaderboards_saving_league_jan?.rank,
                points: data?.user_data?.user?.leaderboards_saving_league_jan?.points,
                users_saving_league: { avatar: data?.user_data?.user?.avatar },
                highlight: true
            })

            setLeaderboard(data.rows); // Set leaderboard data
            setUserData(data.user_data.user); // Set user data

            // Store individual user_data fields in localStorage
            if (data?.user_data?.user?.leaderboards_saving_league_jan) {
                const user = data?.user_data.user?.leaderboards_saving_league_jan;
                localStorage.setItem('savings_points', user.points);
                localStorage.setItem('savings_rank', user.rank);
                localStorage.setItem('savings_transactions', user.transactions);
                localStorage.setItem('savings_total_order_value', user.total_order_value);
                localStorage.setItem('savings_brand_discovery', user.brand_discovery);
                localStorage.setItem('savings_current_streak', user.current_streak);
                localStorage.setItem('milestone_1', data?.user_data?.user.milestone_1)
                localStorage.setItem('milestone_2', data?.user_data?.user.milestone_2)
                localStorage.setItem('milestone_3', data?.user_data?.user.milestone_3)
                localStorage.setItem('milestone_4', data?.user_data?.user.milestone_4)
                localStorage.setItem('milestone_5', data?.user_data?.user.milestone_5)
                localStorage.setItem('milestone_6', data?.user_data?.user.milestone_6)
                localStorage.setItem('milestone_7', data?.user_data?.user.milestone_7)
                localStorage.setItem('segment', data?.user_data?.user?.segment)
                localStorage.setItem('savings_visit_earn',user?.visit_earn * 10)
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    const fetchWeeklyLeaderboard = async () => {
        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/getleaderboard_cdl_week_jan?week=4&ck_id=${ck_id}`)
            const data = await response.json()

            // console.log('====>', data?.user_data?.user)

            data.rows.unshift({
                name: "YOU",
                rank: data?.user_data?.user?.leaderboards_saving_league_snapshot_jans[0]?.rank,
                points: data?.user_data?.user?.leaderboards_saving_league_snapshot_jans[0]?.points,
                users_saving_league: { avatar: data?.user_data?.user?.avatar },
                highlight: true
            })
            setWeeklyLeaderboard(data?.rows)
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    }
    useEffect(() => {
        fetchLeaderboard();
        fetchWeeklyLeaderboard()
    }, []);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [selectedTab, setSelectedTab] = useState('Weekly');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handlePrizeClick = () => {
        if (userData && (userData?.leaderboards_saving_league_jan?.points)) {
            setOpenDataPopup(true)
        } else {
            setOpenTermsCondition(true)
        }
    }
    const updatedAt = new Date(userData?.leaderboards_saving_league_jan?.updatedAt);
    const formattedDate = updatedAt.getDate().toString().padStart(2, '0') + '/' +
        (updatedAt.getMonth() + 1).toString().padStart(2, '0') + '/' +
        updatedAt.getFullYear();

    localStorage.setItem('formated_date',formattedDate)

    console.log(`Last updated on ${formattedDate}`);

    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                background: 'linear-gradient(180deg, #010A26 0%, #05248C 113.17%)',
                width: '100%',
            }}>
                {/* <UserDetail /> */}
                <Grid>
                    <Box
                        sx={{
                            backgroundImage: `url(${BG})`,
                            backgroundRepeat: 'no-repeat',
                            // webkitBackgroundSize: 'cover',
                            backgroundSize: '100% 100%', // Ensures the image covers the entire container
                            width: "100%",
                            padding: "20px 16px",
                            position: "relative",
                            zIndex: "22"
                        }}
                    >
                        <Grid container spacing={2} sx={{marginTop:'40%'}}>
                            <Grid item xs={4}>
                                <Box
                                    sx={{
                                        display: 'flex',         // Enable flexbox layout
                                        flexDirection: 'column', // Stack children vertically
                                        justifyContent: 'flex-end', // Align children to the end of the main-axis (bottom)
                                        backgroundImage: `url(${AvatorBg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%', // Ensures the image covers the entire container
                                        width: "104px",
                                        height: '170px',
                                        marginRight:'0',
                                        alignItems:"center"
                                    }}
                                >
                                    <Box sx={{
                                        overflow:"hidden",
                                        borderRadius:"16px",
                                        width:"90%",
                                        position:"relative",
                                        bottom:"5px"
                                    }}>
                                    {userData && <img
                                        src={require(`../../images/diwali/icon/Avator${userData?.avatar}.png`)}
                                        alt="Avatar"
                                        style={{
                                            width: '100%',
                                            height: '141px',
                                            objectFit: 'cover', // Ensure the image scales correctly
                                        }}
                                    />}
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item xs={8}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: 'Montserrat Alternates',
                                        fontWeight: '800',
                                        color: '#fff',
                                        marginBottom: '8px',
                                        fontSize: '1rem'
                                    }}
                                >
                                    {/* {userData?.leaderboards_saving_league_jan?.name} */}
                                    {userData?.leaderboards_saving_league_jan?.name || 'You'}
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                background: "#003FA5",
                                                borderRadius: '8px',
                                                padding: '8px',
                                                textAlign: 'center',
                                                height: '64px',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '500',
                                                    fontSize: '10px',
                                                    color: '#fff',
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                Monthly Points
                                                <img src={BorderIMG} alt='BorderIMG' style={{ width: '100%' }} />
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontFamily: '"Montserrat Alternates"',
                                                    fontWeight: '800',
                                                    color: '#fff',
                                                    fontSize: '1.25rem',
                                                    marginTop: '-7%'
                                                }}
                                            >
                                                {userData && userData?.leaderboards_saving_league_jan?.points || '0'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                background: " #FFFFFF33",
                                                borderRadius: '8px',
                                                padding: '8px',
                                                textAlign: 'center',
                                                height: '64px',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontFamily: 'Metropolis',
                                                    fontWeight: '500',
                                                    fontSize: '10px',
                                                    color: '#fff',
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                Monthly Rank
                                                <img src={BorderIMG} alt='BorderIMG' style={{ width: '100%' }} />
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontFamily: '"Montserrat Alternates"',
                                                    fontStyle: 'normal',
                                                    fontWeight: '800',
                                                    color: '#fff',
                                                    fontSize: '1.25rem',
                                                    marginTop: '-7%'
                                                }}
                                            >
                                                {userData && userData?.leaderboards_saving_league_jan?.rank || '0'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontFamily: 'Metropolis',
                                        color: '#fff',
                                        marginTop: '8px',
                                        display: 'block',
                                        fontSize: '8px',
                                        fontWeight: '500'
                                    }}
                                >
                                    *Last updated on {formattedDate}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={() => handlePrizeClick()}
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.75rem' }} />}
                                    sx={{
                                        background: '#FFFFFF33',
                                        color: '#fff',
                                        fontWeight: '600',
                                        border: '0.5px solid  rgba(255, 255, 255, 0.6)',
                                       fontFamily: '"Montserrat Alternates"',
                                        fontSize: '0.75rem',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        padding: '12px',
                                        marginTop: '6px',
                                        justifyContent: 'space-between',
                                        marginBottom: '1rem',
                                        fontStyle: "normal",
                                        boxShadow: 'none',
                                        '&:hover': {
                                            background: '#FFFFFF33', // Keep the background same on hover
                                            boxShadow: 'none', // Remove any box-shadow on hover
                                        },
                                    }}
                                    fullWidth
                                >
                                    Prizes Claimed
                                </Button>

                            </Grid>
                        </Grid>

                    </Box>
                </Grid>
                <Grid item xs={12} px={2} pt={5} sx={{ position: 'relative' }}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => navigate('/savings-league/prize-win')}
                        sx={{
                            background: '#FFC600',


                            color: '#fff',
                            fontWeight: 600,
                            fontFamily: 'Metropolis',
                            textTransform: 'none',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            overflow: 'hidden', // To clip the image if necessary
                            height: '54px',
                            paddingLeft: '110px', // Add padding to make room for the image
                            position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                fontWeight: '600',
                                flexGrow: 1, // Ensures the text takes up remaining space
                                textAlign: 'center',
                                fontFamily: 'Metropolis',
                                color:'#000'
                            }}
                        >
                            Prizes you can win 🎉
                            <ArrowRightAltIcon sx={{ fontSize: '24px',color:'#000' }} />
                        </Typography>
                        
                    </Button>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '60%',
                            left: {
                                xs: '30px', // Adjust the position on smaller devices
                                sm: '50px', // Standard position on small and larger devices
                            },
                            transform: 'translateY(-50%)', // Center the image vertically
                            width: {
                                xs: '96px', // Smaller image on mobile devices
                                sm: '96px', // Standard image size on small and larger devices
                            },
                            height: {
                                xs: '96px', // Adjust the height to match the width
                                sm: '96px',
                            },
                        }}
                    >
                        <img
                            src={BikeImage}
                            alt="Prize"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', // Ensures the image covers the area proportionally
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} pt={3.5}>
                    <img src={Leaderbd} alt='Leaderbd' style={{ width: '100%', paddingLeft: '81px', paddingRight: '81px' }} />
                </Grid>
                <Grid item xs={12} sx={{ borderBottom: '1px solid #352066' }} pt={2}>
                    <Button
                        variant="text"
                        onClick={() => handleTabClick('Weekly')}
                        sx={{
                            color: selectedTab === 'Weekly' ? '#FFFFFF' : '#786B95',
                            fontWeight: selectedTab === 'Weekly' ? '600' : '600',
                            borderBottom: selectedTab === 'Weekly' ? '2px solid #7B1FA2' : 'none',
                            fontSize: '14px',
                            borderRadius: 0,
                            textTransform: 'none',
                            padding: '8px 16px',
                            borderRight: '1px solid #352066',
                            fontFamily: selectedTab === 'Weekly' ? 'Metropolis' : 'Metropolis',
                            textShadow: selectedTab === 'Weekly' ? '0px 0px 18px #FFFFFF' : 'none', // Apply white shadow when selected
                            width: '33%',
                            borderBottom: selectedTab === 'Weekly' ? 'none' : 'none',
                        }}
                    >
                        Weekly
                    </Button>

                    <Button
                        variant="text"
                        onClick={() => handleTabClick('Overall')}
                        sx={{
                            color: selectedTab === 'Overall' ? '#FFFFFF' : '#786B95',
                            fontWeight: selectedTab === 'Overall' ? '600' : '600',
                            borderBottom: selectedTab === 'Overall' ? '2px solid #7B1FA2' : 'none',
                            fontSize: '14px',
                            borderRadius: 0,
                            textTransform: 'none',
                            padding: '8px 16px',
                            borderRight: '1px solid #352066',
                            fontFamily: selectedTab === 'Overall' ? 'Metropolis' : 'Metropolis',
                            textShadow: selectedTab === 'Overall' ? '0px 0px 18px #FFFFFF' : 'none', // Apply white shadow when selected
                            width: '34%',
                            borderBottom: selectedTab === 'Overall' ? 'none' : 'none',
                        }}
                    >
                        Overall
                    </Button>
                    <a onClick={scrollToElementt}><Button
                        variant="text"
                        // onClick={() => handleTabClick('T&Cs')}
                        sx={{
                            color: selectedTab === 'T&Cs' ? '#FFFFFF' : '#786B95',
                            fontWeight: selectedTab === 'T&Cs' ? '600' : '600',
                            borderBottom: selectedTab === 'T&Cs' ? '2px solid #7B1FA2' : 'none',
                            fontSize: '14px',
                            borderRadius: 0,
                            textTransform: 'none',
                            padding: '8px 16px',
                            borderRight: '1px solid #352066',
                            fontFamily: selectedTab === 'T&Cs' ? 'Metropolis' : 'Metropolis',
                            textShadow: selectedTab === 'T&Cs' ? '0px 0px 18px #FFFFFF' : 'none', // Apply white shadow when selected
                            width: '33%',
                            borderBottom: selectedTab === 'T&Cs' ? 'none' : 'none',
                        }}
                    >
                        T&Cs
                    </Button></a>

                </Grid>

                <Box sx={{ width: '100%', paddingX: '12px', }} pt={3}>
                    <Grid container sx={{ paddingBottom: '16px', borderBottom: '1px solid #352066' }}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{
                                    color: '#AEA2CA',
                                    fontFamily: 'Metropolis',
                                    fontSize: '10px',
                                    fontWeight: '600',
                                    textAlign: 'left',
                                }}
                            >
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                sx={{
                                    color: '#AEA2CA',
                                    fontFamily: 'Metropolis',
                                    fontSize: '10px',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                }}
                            >
                                Rank
                            </Typography>
                        </Grid>
                        <Grid item xs={2.7}>
                            <Typography
                                sx={{
                                    color: '#AEA2CA',
                                    fontFamily: 'Metropolis',
                                    fontSize: '10px',
                                    fontWeight: '600',
                                    textAlign: 'right',
                                }}
                            >
                                Points
                            </Typography>
                        </Grid>
                        <Grid item xs={0.3}></Grid>
                    </Grid>

                    {selectedTab === 'Overall' && leaderboard && leaderboard.length > 0 ? (
                        leaderboard.map((entry, index) => (
                            <Grid
                                container
                                key={index}
                                sx={{
                                    height: '64px',
                                    background: entry.highlight ? 'linear-gradient(180deg, #83C1FF 0%, #1350BA 100%)'

                                        :`url(${borderline}) no-repeat center bottom`,  
                                        backgroundSize:"293px",
                                    borderRadius: entry.highlight ? '8px' : '0',
                                    marginBottom: entry.highlight ? '8px' : '0',
                                    boxShadow: entry.highlight ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }} px={1}>
                                {entry?.position === 'up' ? <KeyboardArrowUpIcon sx={{color:'#4BFF53'}}/> : entry?.position === 'down' ? <KeyboardArrowDownIcon sx={{color:'#FF7171'}}/> : <RemoveIcon sx={{color:'#fff'}}/>}
                                    <Avatar
                                        src={require(`../../images/diwali/icon/Avator${entry?.users_saving_league_jan?.avatar || 4}.png`)}
                                        alt={entry?.name}
                                        sx={{
                                            width: entry.highlight ? '48px' : '40px',
                                            height: entry.highlight ? '52px' : '40px',
                                            marginRight: index === 0 ? '2px' : '8px',
                                            // paddingX: '7px',
                                            textAlign: 'left',
                                            borderRadius: entry.highlight ? '0px' : '100px',
                                            boxShadow: entry.highlight ? 'none' : '0px 0px 10px rgba(255, 255, 255, 0.5)',
                                            alignSelf: entry.highlight ? 'flex-end' : 'center',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {userData?.ck_id == entry?.ck_id ? "You" : entry?.name == 'You' || entry?.name == '' ? namesArray[index] : entry?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {entry?.rank || '0'}
                                        { }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} px={1}>
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >

                                        {entry?.points || '0'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <Typography sx={{ color: '#FFFFFF', textAlign: 'center',  }}>
                            {/* No leaderboard data available */}
                        </Typography>
                    )}

                    {selectedTab === 'Weekly' && weeklyLeaderboard && weeklyLeaderboard.length > 0 ? (
                        weeklyLeaderboard.map((entry, index) => (
                            <Grid
                                container
                                key={index}
                                sx={{
                                    height: '64px',
                                    background: entry.highlight ? 'linear-gradient(180deg, #83C1FF 0%, #1350BA 100%)'
                                    :`url(${borderline}) no-repeat center bottom`,  
                                    backgroundSize:"293px",
                                    borderRadius: entry.highlight ? '8px' : '0',
                                    marginBottom: entry.highlight ? '8px' : '0',
                                    boxShadow: entry.highlight ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }} px={1}>
                                {entry?.position === 'up' ? <KeyboardArrowUpIcon sx={{color:'#4BFF53'}}/> : entry?.position === 'down' ? <KeyboardArrowDownIcon sx={{color:'#FF7171'}}/> : <RemoveIcon sx={{color:'#fff'}}/>}

                                    <Avatar
                                        src={require(`../../images/diwali/icon/Avator${entry?.users_saving_league_jan?.avatar || 4}.png`)}
                                        // src={require(`../../images/diwali/icon/Avator${entry?.users_diwali?.avatar}.png`)}
                                        // src={`../../images/diwali/icon/Avator${entry?.users_diwali?.avatar}.png`}

                                        alt={entry?.name}
                                        sx={{
                                            width: entry.highlight ? '48px' : '40px',
                                            height: entry.highlight ? '52px' : '40px',
                                            marginRight: index === 0 ? '2px' : '8px',
                                            // paddingX: '7px',
                                            textAlign: 'left',
                                            borderRadius: entry.highlight ? '0px' : '100px',
                                            boxShadow: entry.highlight ? 'none' : '0px 0px 10px rgba(255, 255, 255, 0.5)',
                                            alignSelf: entry.highlight ? 'flex-end' : 'center',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {userData?.ck_id == entry?.ck_id ? "You" : entry?.name == 'You' || entry?.name == '' ? namesArray[index] : entry?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {entry?.rank || '0'}
                                        { }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} px={1}>
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Metropolis',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >

                                        {entry?.points || '0'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <Typography sx={{ color: '#FFFFFF', textAlign: 'center', mt: 2 }}>
                            {/* No leaderboard data available */}
                        </Typography>
                    )}

                </Box>
                <Grid item xs={12} pb={18} ref={formReff}>
                    <Box px={5} pt={6} pb={4}>
                        <img src={TermImg} alt='termImg' width={'100%'} />
                    </Box>

                    <Terms />
                </Grid>

                <ButtonComponent title="Start Collecting Points" url="/savings-league/play-point" />

                <SwipeableDrawer
                    anchor="bottom"
                    open={openTermsCondition}
                    onClose={() => setOpenTermsCondition(false)}
                    onOpen={() => setOpenTermsCondition(true)}
                    variant="temporary"
                    disableSwipeToOpen={true}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    sx={{ width: "100%", marginX: "10px" }}
                    PaperProps={{
                        elevation: 10,
                        style: { backgroundColor: "transparent" },
                    }}
                >
                    <EmptyPopUp setOpen={openTermsCondition} />
                </SwipeableDrawer>

                <SwipeableDrawer
                    anchor="bottom"
                    open={openDataPopup}
                    onClose={() => setOpenDataPopup(false)}
                    onOpen={() => setOpenDataPopup(true)}
                    variant="temporary"
                    disableSwipeToOpen={true}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    sx={{ width: "100%", marginX: "10px" }}
                    PaperProps={{
                        elevation: 10,
                        style: { backgroundColor: "transparent" },
                    }}
                >
                    <DataPopUp setOpen={openDataPopup} />
                </SwipeableDrawer>


            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};