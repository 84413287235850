import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import shopfeaturedbg from "../../images/savings-league/feature-brand.png";
import shopfeaturedimg from "../../images/savings-league/podium 1.png";
import amazonLogo from "../../images/diwali/amazon.png";
import { Box } from "react-feather";
import ButtonComponent from "../../components/saving-league/buttonBottom";
import { useNavigate } from "react-router-dom";

export default function ShopFromBrand() {
  // const [data,setData] = useState(null)
  const segment = localStorage.getItem('segment')
  const navigate = useNavigate()

  const [link,setLink] = useState('')

  const [data,setData] = useState({})

  const fetchBrand = async() =>{
    try {
      let response = await fetch('https://leaderboard-1.earningshub.co/api/user/get_featurebrand_jan')
      let data = await response.json()
      setData(data?.data[0])
      setLink(segment == 1 ? data?.data[0]?.link_active : segment == 2 ? data?.data[0]?.link_lapsed : segment == 4 ? data?.data[0]?.link_active : data?.data[0]?.link_inactive_new)
    } catch (error) {
      console.log(error)
      navigate('/savings-league/index',{replace:true})
    }
  }

  useEffect(()=>{
    fetchBrand()
  },[])


  return (
    <Grid container sx={{ background: "#fff", minHeight:"100%"  }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid
          sx={{
            background: 'linear-gradient(180deg, #010A26 23.35%, #05248C 100%)',
          width: "100%",
           height:"100%"
          }}
        >
          <Grid pb={2}>
            <img src={shopfeaturedbg} style={{ width: "100%" ,marginTop:'-35px'}} />
          </Grid>

          <Grid sx={12} >
            <Grid
              sx={{
                position: "relative",
                top: "-130px",
                marginBottom: "-190px",
              }}
            >
              <img src={shopfeaturedimg} style={{ width: "100%" }} />
              <img
                src={data?.logo_url}
                style={{
                  position: "absolute",
                  top: "26%",
                  left: "0",
                  right: "0",
                  margin: "0 auto",
                  maxWidth: "150px",
                  height:'150px'
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            pb={12}
            pl={4.75}
            pr={4.75}
            style={{ justifyContent: "center" }}
          >
            <Grid sx={12}>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  minHeight:"140px"
                }}
              >
                Note: On the same day if you shop more
                <br /> than once on a particular brand, you get points only once
                
              </Typography>
            </Grid>
          </Grid>
          <a href={link}><ButtonComponent title ={`Shop From ${data?.brand_name}`}/></a>

        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
    </Grid>
  );
}
