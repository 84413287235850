import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Slider, Button, styled } from "@mui/material";

import achivepointsimg from "../../images/savings-league/archive.png";
import thumbimg from "../../images/diwali/thumbimg.png";
import points30bg from "../../images/diwali/points30bg.png";
import ButtonComponent from "../../components/saving-league/buttonBottom";

const VerticalSlider = styled(Slider)({
  height: 400,
});

const marks = [
  { value: 0 },
  { value: 1, label: "+ 30 points" },
  { value: 2, label: "+ 30 points" },
  { value: 3, label: "+ 30 points" },
  { value: 4, label: "+ 30 points" },
  { value: 5, label: "+ 30 points" },
  { value: 6, label: "+ 30 points" },
];

export default function AchievePoints() {
  const [sliderValue, setSliderValue] = useState(0);
  const [marks2, setMarks2] = useState([]);
  const [marks3] = useState(["0", "+ 100 Points", "+ 150 Points", "+ 200 Points", "+ 250 Points", "+ 300 Points", "+ 500 Points", "+ 1000 Points"]);

  useEffect(() => {
    // Retrieve the milestone values from localStorage
    const retrievedMarks = [];
    for (let i = 1; i <= 7; i++) {
      const milestoneValue = localStorage.getItem(`milestone_${i}`) || 0;
      retrievedMarks.push({ value: Number(milestoneValue), label: `${milestoneValue} Points` });
    }
    // Add the 8th value as 0 by default
    retrievedMarks.unshift({ value: 0, label: "0 Points" });


    setMarks2(retrievedMarks);
  }, []);


  const bottomValues = [
    '39.2857%', // 1st div
    '49%',      // 2nd div
    '63.2857%', // 3rd div
    '74%',      // 4th div
    '99%'      // 5th div
  ];


  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  console.log(marks2, "marks2")


  return (
    <Grid container sx={{ background: "#fff" }} >
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ background: "#1F162E" }}>
        <Grid
          sx={{
            background: 'linear-gradient(180deg, #010A26 23.35%, #05248C 100%)',
            width: "100%",
            paddingBottom: "30%",
          }}
        >
          <Grid pb={2} px={3}>
            <img src={achivepointsimg} style={{ width: "100%" ,marginTop:'-35px'}} />
          </Grid>
          <Typography
            sx={{

              color: Number(localStorage.getItem('savings_points')) >= localStorage.getItem('milestone_7') ? "#FE8B37" : "#164BA6",
              textAlign: "center",
              fontFamily: "Metropolis",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "10px",
              paddingBottom: "32px",
              paddingTop: "0px",
            }}
          >
            All points claimed
          </Typography>

          <Grid item sx={{ height: "368px", boxSizing: "border-box", marginBottom: "32px" }}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                height: "368px",
                boxSizing: "border-box",
                left: "-20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "368px",
                  zIndex: "11",
                  boxSizing: "border-box",
                  position: "relative"
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: "368px",
                    width: "60px",
                    position: "relative",
                    boxSizing: "border-box",
                    left: "-20px",
                  }}
                >
                  {marks.reverse().map((mark, index) => (
                    <div
                      key={index}
                      style={{
                        color: "#7524CE",
                        fontFamily: "Metropolis",
                        fontSize: "10px",
                        lineHeight: "1",
                        position: "absolute",
                        bottom: bottomValues[index] || '39%', 
                      }}
                    >
                      {mark.label}
                    </div>
                  ))}
                </div> */}

                <VerticalSlider
                  aria-label="Temperature"
                  defaultValue={localStorage.getItem("savings_points") || 0}
                  value={localStorage.getItem("savings_points") || 0}
                  onChange={handleSliderChange}
                  valueLabelDisplay="off"
                  marks={marks2.map((mark) => {
                    return {
                      value: mark.value,
                      label: mark.label,
                    }
                  })}
                  orientation="vertical"
                  min={0}
                  // step={100}
                  max={localStorage.getItem('milestone_7')}
                  // max={marks2[marks2.length-1]?.value}
                  sx={{
                    color: "transparent",
                    boxShadow: "0px 0px 0px",
                    borderRadius: "20px",
                    zIndex: "222",
                    position: "relative",
                    height: "368px",
                    position: "relative",
                    left: "-50px",
                    boxSizing: "border-box",
                    '&.Mui-disabled': {
                      display: "none",
                      color: '#E85F34', // Custom color for disabled state
                    },
                    "& .MuiSlider-thumb": {
                      height: 26,
                      width: 37,
                      boxShadow: "inherit !important",
                      background: `url(${thumbimg})`,
                      display: "none",
                      border: "0px solid currentColor",
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "inherit",
                      },
                      ":before": {
                        boxShadow: "0px 0px 0px",
                      },
                    },
                    "& .MuiSlider-track": {
                      width: 6,
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#164BA6",
                      opacity: "1",
                      width: 6,
                      display: "none"
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#164BA6",
                      height: 2,
                      display: "none",
                      width: 14,

                      borderRadius: "20px",
                      "&.MuiSlider-markActive": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiSlider-markLabel": {
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "10px",
                      fontStyle: "normal",
                      lineHeight: "10px",
                      fontWeight: "400",
                      marginLeft: "10px",
                    },
                    // "& .MuiSlider-markLabel.MuiSlider-markLabelActive": {
                    //   background: `url(${points30bg})`,
                    //   color: "#FE8B37",
                    //   backgroundSize: "cover",
                    //   backgroundPosition: "center",
                    //   width: "106px",
                    //   backgroundPosition: "-10px !important",
                    //   height: "52px !important",
                    //   backgroundPosition: "100%",
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "flex-start",
                    //   backgroundRepeat: "no-repeat",
                    // },
                  }}
                />
                <VerticalSlider
                  aria-label="Temperature"
                  className="achivePointSlider"
                  defaultValue={localStorage.getItem("savings_points") || 0}
                  value={localStorage.getItem("savings_points") || 0}
                  onChange={handleSliderChange}
                  valueLabelDisplay="off"
                  marks={marks2.map((mark, ind) => ({
                    value: mark.value,
                    label: marks3[ind] == 0 ? "" : marks3[ind],
                  }))}
                  orientation="vertical"
                  min={0}
                  // step={100}
                  max={localStorage.getItem('milestone_7')}
                  // max={marks2[marks2.length-1]?.value}
                  sx={{
                    color: "#E85F34",
                    boxShadow: "0px 0px 0px",
                    borderRadius: "20px",
                    zIndex: "222",
                    position: "relative",
                    height: "368px",
                    boxSizing: "border-box",
                    '&.Mui-disabled': {
                      color: '#E85F34', // Custom color for disabled state
                    },
                    "& .MuiSlider-thumb": {
                      height: 26,
                      width: 37,
                      boxShadow: "inherit !important",
                      background: `url(${thumbimg})`,
                      border: "0px solid currentColor",
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "inherit",
                      },
                      ":before": {
                        boxShadow: "0px 0px 0px",
                      },
                    },
                    "& .MuiSlider-track": {
                      width: 6,
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#164BA6",
                      opacity: "1",
                      width: 6,
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#164BA6",
                      height: 2,
                      width: 14,
                      borderRadius: "20px",
                      "&.MuiSlider-markActive": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiSlider-markLabel": {
                      color: "#164BA6",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "10px",
                      paddingLeft: "10px"
                    },
                    "& .MuiSlider-markLabel.MuiSlider-markLabelActive": {
                      background: `url(${points30bg})`,
                      color: "#FE8B37",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "106px",
                      backgroundPosition: "-10px !important",
                      height: "52px !important",
                      backgroundPosition: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundRepeat: "no-repeat",
                      "& .MuiSlider-markLabel.MuiSlider-markLabelActive:nth-child(1)": {
                        display: "none"
                      }
                    },
                  }}
                />
              </div>
            </Box>
          </Grid>
          <Grid>
            <Box>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.50)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  paddingBottom: "6px",
                  lineHeight: "140%",
                }}
              >
                *Last updated on {localStorage.getItem('formated_date')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <a href='cashkaro://home'><ButtonComponent title="Start Collecting Points" /></a>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>{" "}
    </Grid>
  );
}
