import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
     
      <ol>
        <li>
          <strong>Eligibility</strong>
          <p>The VIP Pass is available only for a select group of users on the CashKaro app. If you did not receive the link directly from CashKaro, you will not be able to access the campaign after registration.</p>
        </li>
        <li>
          <strong>Pass Validity</strong>
          <p>The pass is valid from the date of purchase until the end of the purchase month. For example, passes purchased on any date in December expire on 31st January.  </p>
        </li>
        <li>
          <strong>Accessing Increased Cashback</strong>
          <p>Increased cashback rates are accessible only through links specifically provided on the "Increased Cashback" page within the CashKaro app. Purchases made through other pages or direct retailer links will not qualify for increased cashback rates.</p>
        </li>
        <li>
          <strong>Accelerated Cashback Timelines</strong>
          <p>
Accelerated cashback timelines are applicable only to transactions automatically tracked by the CashKaro app. Transactions requiring the submission of a missing cashback ticket do not qualify for accelerated timelines.
 </p>
        </li>
        <li>
          <strong>Additional Bonuses</strong>
          <p>Bonuses for multi-brand shopping and cumulative spend are awarded only after all relevant transactions are confirmed as non-refunded and non-cancelled.</p>
        </li>
        <li>
          <strong>Modifications to the Pass</strong>
          <p>CashKaro reserves the right to modify, suspend, or discontinue the Cashback Plus Pass at any time without prior notice.</p>
        </li>
        <li>
          <strong>Non-Transferability</strong>
          <p>The pass is non-transferable and may only be used by the registered account holder to whom it was issued.</p>
        </li>
        <li>
          <strong>Acceptance of Terms</strong>
          <p>By purchasing and/or using the CashKaro VIP Pass, users agree to adhere to these terms and conditions.</p>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
