import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Paper, Typography, Box, FormHelperText, Stack, Divider, Alert, TextField, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CongratulationsPopup from '../../components/saving-league/congratulation';


export default function AlertDialog({ open, onClose }) {
    const [error, setError] = useState('');
const [mobile, setMobile] = useState('');
const navigate = useNavigate();
    const [openPopup, setOpenPopup] = useState(false);
    const [isRepeat, setIsRepeat] = useState('');
    
    const handleClosePopup = () => {
        setOpenPopup(false);
    };
     useEffect(() => {
            // Scroll to top
            window.scrollTo(0, 0);
        
            // Disable scrolling after scrolling to the top
            document.body.classList.add('no-scroll');
            
            // Check if 'diwali_ck_id' exists in localStorage
            const ckId = localStorage.getItem('savings_league_ck_id');
            if (ckId !== null) {
                setIsRepeat('repeat');
            } else {
                setIsRepeat('no-repeat');
            }
        
            // Cleanup function to remove the class
            return () => {
                document.body.classList.remove('no-scroll');
            };
        }, []);
const handleButtonClick = async () => {
    // Check if mobile number is not filled
    if (!mobile) {
        setError('Please enter your mobile number.');
        return; // Stop further execution
    }
    // Validate mobile number format (assuming 10-digit number for example)
    if (!/^\d{10}$/.test(mobile)) {
        setError('Please enter a valid 10-digit mobile number.');
        return;
    }
    const params = new URLSearchParams(window.location.search);
    const ck_id = params.get('ck_id');

    try {
        const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/create_cdl_jan?${ck_id ? `userId=${ck_id}` : ''}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contact: mobile,
            }),
        });

        const data = await response.json();

        if (data.status === 1) {
            localStorage.setItem('savings_league_ck_id', data?.ck_id);
            setOpenPopup(true); // Show the popup
        } else {
            setError(data.message); // Alert for demonstration
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
    }
};
    return (
       
            <Grid container>
                 <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Box>
                    <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-paper": { // Target the paper element of the dialog
                        margin: 2, // Remove margin
                        maxWidth: 'unset', // Optional: Adjust width constraints
                        width: '100%', // Optional: Full width
                        background: 'linear-gradient(180deg, #6CB8FF 0%, #2654F7 91.01%)',
                    }
                }}
            >
                <DialogContent sx={{ padding: '10px 10px' }}>
                    <DialogContentText sx={{ fontWeight: '600', fontSize: '0.875rem', fontFamily: 'metropolis', color: '#fff' }}>
                        Enter CashKaro registered mobile number
                    </DialogContentText>
                    <Grid item xs={12}>
                        <TextField
                            type="tel"
                            fullWidth
                            value={mobile}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    setMobile(value);
                                    setError(''); // Clear error on valid change
                                } else {
                                    setError('Mobile number must be 10 digits');
                                }
                            }}
                            variant="outlined"
                            placeholder="98881-52312"
                            error={Boolean(error)}
                            helperText={error}
                            FormHelperTextProps={{ sx: { color: 'red', marginTop: '8px' } }}
                            InputProps={{
                                style: {
                                    color: '#FFF',
                                    borderRadius: '8px',
                                    border: '1px solid #FFFFFF99',
                                    background: '#FFFFFF14',
                                    marginTop: '1rem',
                                    height: '48px !important',
                                },
                            }}
                        />
                    </Grid>
                    <Box sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleButtonClick}
                            sx={{
                                backgroundColor: '#FFC600',
                                color: '#212121',
                                borderRadius: '8px',
                                padding: '12px 16px',
                                textTransform: 'none',
                                width: '100%',
                                maxWidth: '400px',
                                fontSize: '16px',
                                fontFamily: 'Metropolis',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: '#FFC600',
                                },
                            }}
                        >
                            Register
                        </Button>
{openPopup && (
                                <>
                                    {/* {isRepeat === "repeat" && <CongratulationsPopupRepeat open={openPopup} onClose={handleClosePopup} />} */}
                                    {isRepeat === "no-repeat" && <CongratulationsPopup open={openPopup} onClose={handleClosePopup} />}
                                </>
                            )}

                    </Box>

                </DialogContent>
            </Dialog>
                    </Box>
                 
                  </Grid>
                   <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
            </Grid>
 
        
           
        
    );
}
